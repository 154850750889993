
const LOCAL_URL = 'http://localhost:3001';
const SERVER_URL = 'https://www.helsword.org:443';
const LOCAL_NET = 'http://192.168.0.141:3001'

const baseUrl = SERVER_URL;

module.exports = {
    baseUrl
};
