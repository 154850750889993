import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import userStore from '../store/userStore';
import config from '../plugins/hosted';

const apiUrl = config.baseUrl;

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]); // Changed from a single error to an array
    const nav = useNavigate();
    const { setUser, user } = userStore();

    useEffect(() => {
        if (user) {
            nav('/profile');
        }
    }, [user, nav]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrors([]); // Clear previous errors

        try {
            const response = await axios.post(`${apiUrl}/api/users/login`, {
                username,
                password
            });

            const data = response.data;

            if (response.status === 200) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                setUser(data.user);
                nav('/profile');
            }
        } catch (error) {
            let errorMessages = [];

            if (error.response) {

                errorMessages = error.response.data.errors || [error.response.data.message || 'An unknown error occurred'];
            } else if (error.request) {
                errorMessages = ['No response from the server.'];
            } else {
                errorMessages = ['Error setting up the request.'];
            }

            setErrors(errorMessages);
        }
    };

    return (
        <div className='flex flex-col'>
            <form onSubmit={handleLogin} className="max-w-md mx-auto flex flex-col items-center p-4">
                <h1 className='mb-3 text-2xl'>Login</h1>
                <label className="input input-bordered flex items-center gap-2 mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                         className="h-4 w-4 opacity-70">
                        <path
                            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z"/>
                    </svg>
                    <input
                        type="text"
                        className="grow p-2"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </label>

                <label className="input input-bordered flex items-center gap-2 mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                         className="h-4 w-4 opacity-70">
                        <path fillRule="evenodd"
                              d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                              clipRule="evenodd"/>
                    </svg>
                    <input
                        type="password"
                        className="grow p-2"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>

                {errors.length > 0 && (
                    <div className="text-red-500 mb-4 text-center">
                        {errors.map((error, index) => (
                            <p key={index}>{error}</p>
                        ))}
                    </div>
                )}

                <button type="submit" className="btn w-full btn-primary">
                    Login
                </button>
            </form>
        </div>
    );
};

export default Login;
